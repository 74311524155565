import { template as template_4bfa9e206b4d4ab9af6d778020eb483d } from "@ember/template-compiler";
const FKLabel = template_4bfa9e206b4d4ab9af6d778020eb483d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
