import { template as template_1a779fcc9d5545de837819da07fd5bc4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1a779fcc9d5545de837819da07fd5bc4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
